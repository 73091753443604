<template>
    <v-container>
      <!-- Logo and Download Button -->
      <LogoSection />

      <!-- Video Section with Fullscreen Playback -->
      <VideoSection />

      <!-- Pitch Deck PDF Download Section -->
      <PitchDeckSection />

      <!-- YouTube Video with Download Option -->
      <YouTubeSection />

      <!-- Social Links Section -->
      <SocialLinksSection />

      <!-- Photo Gallery Slider -->
      <PhotoGallerySection />
    </v-container>
</template>

<script>
import LogoSection from '../components/LogoSection.vue';
import VideoSection from '../components/VideoSection.vue';
import PitchDeckSection from '../components/PitchDeckSection.vue';
import YouTubeSection from '../components/YouTubeSection.vue';
import SocialLinksSection from '../components/SocialLinksSection.vue';
import PhotoGallerySection from '../components/PhotoGallerySection.vue';

export default {
  components: {
    LogoSection,
    VideoSection,
    PitchDeckSection,
    YouTubeSection,
    SocialLinksSection,
    PhotoGallerySection,
  },
};
</script>