<template>
  <div>
    <v-row class="justify-center my-5">
      <v-col cols="12" md="6" class="text-center">
        <h2>Pitch Deck</h2>
        <v-btn color="green darken-3" dark @click="downloadPDF" style="letter-spacing: 0;text-transform: none;">
          Download PDF
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    downloadPDF() {
      // Enlace directo al PDF en la carpeta public
      const pdfPath = `${window.location.origin}/presentacion.pdf`;

      // Crear un enlace temporal para descargar el PDF
      const link = document.createElement('a');
      link.href = pdfPath;
      link.download = 'PitchDeck_180Pipe.pdf';
      link.click();
    },
  },
};
</script>

<style scoped>
.v-btn {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
}
</style>
