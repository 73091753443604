<template>
  <div>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-carousel cycle height="500px">
          <v-carousel-item
            v-for="(image, index) in galleryImages"
            :key="index"
            @click="openImage(image)"
          >
            <v-img :src="image" class="fill-height"></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

    <!-- Dialog for Enlarged Image -->
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-btn icon @click="closeDialog" class="close-btn white--text">
          <i class="fas fa-times"></i>
        </v-btn>
        <v-img :src="selectedImage" class="enlarged-image"></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      galleryImages: [
        require('@/assets/1.jpg'),
        require('@/assets/2.jpg'),
        require('@/assets/3.jpg'),
        require('@/assets/4.jpg'),
        require('@/assets/5.jpg')
      ],
      dialog: false,
      selectedImage: null,
    };
  },
  methods: {
    openImage(image) {
      this.selectedImage = image;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.selectedImage = null;
    },
  },
};
</script>

<style scoped>
.enlarged-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}
</style>
