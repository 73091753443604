<template>
  <div>
    <!-- Video Thumbnail to Open Fullscreen Video -->
    <v-row class="justify-center">
      <v-col cols="12" md="8">
        <v-card class="video-thumbnail" @click="playFullscreenVideo">
          <v-img src="@/assets/tumblr.png" height="400px" class="video-placeholder" />
        </v-card>
      </v-col>
    </v-row>

    <!-- Fullscreen Video Dialog -->
    <v-dialog v-model="videoDialog" fullscreen>
      <v-card class="video-dialog-card">
        <div class="video-container">
          <iframe 
            class="fullscreen-video"
            :src="videoUrl"
            allow="autoplay"
          ></iframe>
          <!-- Floating Close Button -->
          <div @click="closeVideo" class="close-btn">
            X
          </div>
          <!-- Download Button -->
          <a :href="downloadUrl" download class="download-btn">
            Download Video
          </a>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoDialog: false,
      videoUrl: 'https://drive.google.com/file/d/1weyrA7FALm1LxxFzjRW8HJbhABzOrvLk/preview',
      downloadUrl: 'https://drive.google.com/uc?export=download&id=1weyrA7FALm1LxxFzjRW8HJbhABzOrvLk',
    };
  },
  methods: {
    playFullscreenVideo() {
      this.videoDialog = true;
      this.videoUrl = 'https://drive.google.com/file/d/1weyrA7FALm1LxxFzjRW8HJbhABzOrvLk/preview';
    },
    closeVideo() {
      this.videoDialog = false;
      this.videoUrl = ''; // Pausa el video al cerrar
    },
  },
};
</script>

<style scoped>
.video-thumbnail {
  cursor: pointer;
}
.video-dialog-card {
  padding: 0;
}
.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fullscreen-video {
  width: 100%;
  height: 100%;
  border: none;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-btn {
  position: absolute;
  top: 60px;
  right: 12px;
  cursor: pointer;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}
</style>
