<template>
  <div class="logo-section">
    <v-row class="justify-center align-center my-5">
      <v-col cols="12" md="6" class="text-center">
        <v-img src="@/assets/logo.png" max-width="200" class="logo-image mb-4" />
        <v-btn color="green darken-3" dark @click="downloadLogo" style="letter-spacing: 0;" small>
          Download Logo
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    downloadLogo() {
      // Crea un enlace temporal para descargar el logo
      const link = document.createElement('a');
      link.href = require('@/assets/logo.png');
      link.download = '180Pipe_Logo.png';
      link.click();
    },
  },
};
</script>

<style scoped>
.logo-section {
  padding: 2rem;
}
.logo-section:hover {
  transform: scale(1.01);
}
.logo-image {
  margin: 0 auto;
  display: block;
  border-radius: 8px;
}
.v-btn {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 0.75rem 2rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}
</style>
